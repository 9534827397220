import { Box, Button } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function DownloadItem({ property, url, index, Byline }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const sitename  = useGet(_bootstrap).sitename;
  
  return (
    <Box
      sx={{
        backgroundColor: "#f3f3f3",
        padding:{md: "24px 48px", xs: "16px"},
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            <div className="h4">
            { ["Prisliste", "Brochure"].includes(property) ? `${buttonsLabel?.filter( btn => btn.UILabelProperty === property)[0]?.Label} - ${sitename}` : property }
            </div>
          </Box>
          {/* <div>{Byline}</div> */}
          
          <div className="paragraph1-regular">{ index ? Byline : buttonsLabel?.filter( btn => btn.UILabelProperty === 'DownloadPrislistetekst')[0]?.Label }</div>
        </Box>
        <Box sx={{
             display: "flex",
            }}>
          <Button
            variant="link"
            href={url}
            target="_blank"
            sx={{
              fontSize: 17,
              color: "#000000",
              p: 0,
              justifyContent: 'flex-end'
            }}
          >
            <Box
              component="span"
              sx={{
                display: {
                  lg: "inline-block",
                  xs: "none",
                },
                mr: {
                  lg: "30px",
                },
              }}
            >
              Download PDF
            </Box>
            <Box
              sx={{
                display: "inline-flex",
                backgroundColor: "primary.main",
                width: 40,
                height: 40,
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon icon="download" sx={{ fill: "#ffffff", fontSize: 16 }} />
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

DownloadItem.propTypes = {
  property: PropTypes.string,
  url: PropTypes.string,
};

export default DownloadItem;
