import { useNavigate }     from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { _bootstrap}       from 'state/store.bootstrap'
import { useAtom }         from 'state/jotai'
import { animation }       from './Dropdown.Animation'
import { Icon }            from 'assets/Icons_'
import { Expand, Option, Cell, Sub, Split, Text } from './Dropdown.Foldout.style'
import { params_query } from 'state/store.global'

export const Foldout = ({ state }) => {

  const { dropdown } = useAtom(_bootstrap)[0]  
  const navigate = useNavigate()
  const params       = useGet(params_query);

  const click = (a,b) => {
    const to   = '/' + a + '/' + b + (params.length ? params : '')
    navigate(to)    
  }

  return (
    <AnimatePresence>
      { state && (
        <Expand clr={window.clr} {...animation}>
        {
          dropdown.options.map(({ SlugEnd:q, Presentation:a, sub1:b },key) => (
            <span key={key}>
            { key > 0 && <Split/> }
              <Option>
                <Cell onClick={()=>click(q,a!=='Oversigt'?'liste':'luftfoto')}>
                  <Text>{ a }</Text>
                </Cell> 
                <div style={{display:'flex'}}>
                  { a != 'Oversigt' && <Comp1 c={()=>click(q,'liste')} /> }
                  { b == 'Luftfoto' && <Comp2 c={()=>click(q,'luftfoto' )}/> }
                  { b == 'Isometri' && <Comp3 c={()=>click(q,'isometri' )}/> }
                </div>
              </Option>
            </span>
            )
          )
        }
        </Expand>
      )}
    </AnimatePresence>
  )
}

const p = { size:20, color:clr.primary_text }
const Comp1 = ({c}) => <Sub onClick={c}><Icon {...p} icon='menu_list'/></Sub>
const Comp2 = ({c}) => <Sub onClick={c}><Icon {...p} icon='menu_area'/></Sub>
const Comp3 = ({c}) => <Sub onClick={c}><Icon {...p} icon='menu_model'/></Sub>
