import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'

export const Figure = styled.figure`
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  // ${BREAK_.md_up} {
  //   top: 24px;
  //   right: ${({rmode}) => rmode ? 42 : 42 }px;
  // }


  background-color: #ffffffbf;
  border-radius: 6px;
  // padding: 5px 8px;
  
  svg {
    width: 30px;
    height: 30px;
  }

  
  ${BREAK_.md_dn} {
    // top: ${({rmode,pin, projectType}) =>(pin > 0 || projectType === 'mono') && rmode ? '15px' :  '95px'};
    // right: ${({rmode}) => rmode ? 15 : 15 }px;
    // padding: 3px 8px;

    svg {
      width: 30px;
      height: 30px;
    }
  }
`
export const Text = styled.text`
  font-size: 20px;
  font-weight: 900;
  fill: #000;
`
export const Group = styled.g`  
`
export const Line = styled.line`
  stroke: #000;
  stroke-width: 5px;
`
export const Circle = styled.circle`
  fill: none;
  stroke: #000;
  stroke-width: 2;
`
export const Svg = styled.svg`
`


export const Label = styled.div`
`;
