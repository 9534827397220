import React, { useEffect, useRef, useState } from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
  // import Compare from "components/Compare/Compare";
import { Compare } from 'components/Compare2/Compare'
import { useBreak } from "hooks/useBreak";
import { Scrollbar } from "react-scrollbars-custom";
import { getFocus, useGet } from "state/jotai";
import { _project } from "state/store.projects";
import { active_project } from "state/store.global";

export const ViewCompare = () => {
  const isDesktop = useBreak('md_up')
  const [scrolltop, setScrolltop] = useState(0)

  return (
    <>
    {
      isDesktop 
      ?
      <Scrollbar onScroll={(e)=> setScrolltop(e?.scrollTop)} trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
        },
      }}>
          <RenderComponent scrolltop={scrolltop} isDesktop={isDesktop} />
      </Scrollbar>
      :
      <>
      <RenderComponent isDesktop={isDesktop} />
      </>
    }
    </>
  )
};


const RenderComponent = ({ isDesktop, scrolltop }) => {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const cardRef   = useRef(null)

  const mainContainer               = useRef(null)
  const [showDiff, setShowDiff]     = useState(false);
  const [isScrolled, setIsScrolled] = useState(false)

    // difference showing switch
  const handleSwitchChange = () => {
    setShowDiff((prevShowDiff) => !prevShowDiff);
  };

  useEffect(() => {
    const parentScrollbar = document.querySelector(".ScrollbarsCustom-Scroller"); 
    const handleScroll = () => {
      if (cardRef.current) {
        const { top } = cardRef.current.getBoundingClientRect();
        setIsScrolled(((329 + top) < 295) )
      }
    };

    parentScrollbar?.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);
      parentScrollbar?.addEventListener('scroll', handleScroll);
    };
  }, [scrolltop]);


  const isInViewport = (element) => {
   
      //get how much pixels left to scrolling our ReactElement
      const rect = element.current.getBoundingClientRect();

      const handleScroll = () => {
        if (cardRef?.current) {
          const { top, bottom } = cardRef.current.getBoundingClientRect();
          setIsScrolled(((449 + top) < 295) )
          // setIsVisible(top < windowHeight && bottom >= 0);
        }
      };
      
      handleScroll();
};

const props = { showDiff, handleSwitchChange, isScrolled, setIsScrolled, isInViewport }
  return <>
          <Box sx = {{ width: '100%', position: "fixed", top: isDesktop ? "134px" : "121px", paddingBottom: isDesktop ? "25px" : "10px", px: isDesktop ? "48px" : "16px", zIndex: '5', background: "#fff"}}>
        <FormControlLabel
          control={
            <Switch
              checked  = {showDiff}
              onChange = {handleSwitchChange}
              sx       = {{
                "& .MuiSwitch-switchBase": {
                  color: '#A9A9A9',
                },
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: clr?.primary,
                },
                "& .MuiSwitch-switchBase + .MuiSwitch-track": {
                  backgroundColor: '#A9A9A9bf',
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: clr?.primary,
                }
              }}
            />
          }
          label = { buttonsLabel?.filter( btn => btn.UILabelProperty === 'differenceSwitchBtn')[0]?.Label || "Vis kun forskelle" }
        />
      </Box>
        <Box sx={{ overflow: "hidden", height: "100%" }}>
          <div ref={mainContainer} style={{
            height  : "calc(100% - 65px)",
            overflow: 'auto',
           }} onScroll={()=> isInViewport(cardRef) }>
            <Compare passRef = {cardRef} containerRef = {mainContainer} {...props} />
          </div>
          {/* <Compare /> */}
        </Box>
  </>
}