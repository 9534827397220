import { Box, Grid }        from "@mui/material";
import { Icon }             from "components/Icons";
import { useBreak } from "hooks/useBreak";
import PropTypes            from "prop-types";
import React                from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project }   from "state/store.global";
import { _project }         from "state/store.projects";
import styled               from "styled-components/macro";

function Highlights({ items }) {
  const { pin, pid }    = useGet(active_project);
  const highlightsLabel = getFocus(_project, "Highlights")?.[pin]?.[pid] ?? [];
  const isMobile = useBreak("md_dn");

  return (
    <>
      <HighlightBox className = "box" isMobile={isMobile}>
      <Box sx={{ display: "flex", gap: "48px", width: "271px",  mx: { xs: "auto", lg: "90px" }, justifyContent: { xs: 'space-between', lg: 'center'} }} isMobile={isMobile}>
          <Items        className = "item-1">
            {items.map((item, i) => {
              if (i % 2 === 0) {
                  // For the even item
                return (
                  <div key={`item1-${i}`}>
                   <HighlightItem item     = {item} />
                  </div>
                );
              }
            })}
          </Items>
          <Items className = "item-2">
            {items.map((item, i) => {
              if (i % 2 !== 0) {
                  // For the odd item
                return (
                  <div key={`item2-${i}`}>
                   <HighlightItem item     = {item} />
                  </div>
                );
              }
            })}
          </Items>
        </Box>
      </HighlightBox>
    </>
  );
}

Highlights.propTypes = {
  items: PropTypes.array.isRequired,
};

function HighlightItem({ item, index }) {
  const { pin, pid }    = useGet(active_project);
  const highlightsLabel = getFocus(_project, "Highlights")?.[pin]?.[pid] ?? [];
  
  return (
    <Box
    sx={{
      display   : "flex",
      lineHeight: 1.3,
      gap: '8px',
      alignItems: "center",
      // my        : 1,
      minHeight : "50px"
    }}
  >
    <Box
      sx={{
        display   : "flex",
      }}
    >
      
      <Icon
        icon = {item?.icon}
       isFaIcon={item?.icon==="compas" ? true : false}
       size={item?.icon==="compas" ? "xl" : ""}
      //  style={{paddingLeft:"9px"}}
        sx = {{
          width: '25px',
          height: '25px',
        }}
      />
    </Box>
    <div>
    <div className="paragraph1-light" style={{ whiteSpace: 'nowrap' }}>
        {
          highlightsLabel?.filter(
            (highlight) => 
              highlight.Property === item.property
          )[0]?.Label
        }
      </div>
      <div className="h4" style={{ whiteSpace: 'nowrap' }}>
        {item?.value} {item?.postfix}
      </div>
    </div>
  </Box>
  );
}

HighlightItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Highlights;

const HighlightBox = styled.div`
  // margin: ${({isMobile}) => isMobile ? "0 61px" : "auto"};
`;

// const Container = styled.div`
//   display        : flex;
//   justify-content: flex-start;
//   padding: ${({isMobile}) => isMobile ? "0 61px" : "0 30px"};
//   gap:48px;
// `;

const Items = styled.div`
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  gap            : 16px;
  max-width: 50%;
`;
