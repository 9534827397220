import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const FullWidth = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index:50;
  background-color: ${({clr, isBgTrue }) => isBgTrue && `${clr?.footer_bgcolor}` };
  ${BREAK_.md_up} {
    /* margin-left: ${({ nav, left }) =>
      (nav && MENU.DESKTOP_NAV_DRAWER_W) +
      (left && MENU.DESKTOP_FILTER_DRAWER_W)}px; 
    width: ${({ nav, left, right }) =>
      `calc(100% - ${
        300 +
        (nav && MENU.DESKTOP_NAV_DRAWER_W) +
        (left && MENU.DESKTOP_FILTER_DRAWER_W) +
        (right && MENU.DESKTOP_UNIT_DRAWER_W)
      }px)`}; */
  };
  ${BREAK_.md_dn} {
    left: 0;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const FlexBox = styled.div`
  padding: 16px 48px 8px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ clr }) => clr?.primary_text};
  font-size: 1.125rem;
  transform: translate(0, 0);
  ${BREAK_.md_dn} {
    padding: 8px 16px 8px 16px;
    font-size: 1rem;
    width: 100%;
    border-radius: 0;
  }
`

export const Splitter = styled.div`
  height: calc(100% - 15px);
  width: 2px;
  background-color: lightgray;
  border-radius: 99px;
`

export const Section = styled.div`
  display: flex;
  background-color: ${({ clr , pin, type, isIsometry, isMobile}) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}bf` : `#ffffffbf` : `${clr?.primary}bf`};
  height: 40px;
  border-radius: 6px;
  border: 2px solid ${({ clr , pin, type, isIsometry, isMobile }) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}` : `#ffffff` : `${clr?.primary}`};
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding ? padding : "0"};
  gap: 8px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  ${BREAK_.md_up} {
    height: 40px;
    width: ${({width}) => width ? width : '185px'};
  }
`