class Unit {
  data = {};

  constructor(data) {
    this.data = data;
  }

  getId = () => {
    return this.data?.UnitID ?? "";
  };

  getProjectNo = () => {
    return this.data?.Data?.Projeknr?.Value ?? "";
  };

  getValue(property = "", def = "") {
    return this.data?.Data?.[property]?.Value ?? def;
  }

  getUnformattedValue(property = "", def = "") {
    return this.data?.Data?.[property]?.UnformattedValue ?? def;
  }

  getPostfix(property = "", def = "") {
    return this.data?.Data?.[property]?.Postfix ?? def;
  }

  getLabel(property = "", def = "") {
    return this.data?.Data?.[property]?.Label ?? def;
  }


  getStatus() {
    return {
      id   : this.getValue("UnitStatusID"),
      label: this.getValue("UnitStatusName"),
      color: this.getValue("UnitColor"),
    };
  }

  getAddress() {
    return `${this.getValue("Adresse")}`;
  }

  getTownZipCode() {
    return `${this.getValue("ZipcodeID")} ${this.getValue("By")}`;
  }

  getImages(floorplan = true) {
    const gallery = this.data?.Gallery?.ListOfPhotos ?? [];
    if (!floorplan) {
      return gallery.map((i) => ({ url: i?.Url })).slice(1);
    }
    return gallery.map((i) => ({ url: i?.Url }));
  }
   // for getting thumb _A images with URLThumbnail:-
   getThumb(floorplan = true) {
    const gallery = this.data?.Gallery?.ListOfPhotos ?? [];
    if (!floorplan) {
      return gallery.map((i) => ({ url: i?.UrlThumbnail })).slice(1);
    }
    return gallery.map((i) => ({ url: i?.UrlThumbnail }));
  }


  isRent() {
    return this.getValue("SalesTypeLejelejlighed");
  }

  getPrice() {
    const val = this.isRent() ? this.getValue("Husleje") : this.getValue("Pris")
    const price = this.isRent()
    ? this.getValue("Husleje") + " " + (parseFloat(this.getValue("Husleje")) > 0 ? this.getPostfix("Husleje"): "")
    :  this.getValue("Pris") + " " + this.getPostfix("Pris")
      // const postfix = this.getPostfix("Pris");
    return ((val === "0") || !Boolean(val) ) ? "" : `${price}`;
  }

  getDescriptionTitle() {
    return this.getValue("UnitDescriptionHeadline");
  }

  getMoveInDate() {
    return this.getUnformattedValue("Indflytningsdato");
  }

  getDescription() {
    return this.getValue("UnitDescription");
  }

  getAreaDescriptionTitle() {
    return this.getValue("AreaDescriptionHeadline");
  }

  getAreaDescription() {
    return this.getValue("AreaDescription");
  }

  getProperties(isComparable = false) {
    if (!this.data?.Groups?.length || !this.data?.Data) {
      return [];
    }

    const unitGroups = this.data.Groups;
    const unitData   = this.data.Data;

    const groupsObject = Object.keys(unitData).reduce((g, key) => {
      const data      = unitData[key];
      const groupName = data.GroupName;
      if (
        !groupName ||
        false === data?.ShowValue ||
        !unitGroups.includes(groupName)
      ) {
        return g;
      }

      if (isComparable && !data?.Comparable) {
        return g;
      }

      if (!g[groupName]) {
        g[groupName] = [];
      }
      g[groupName].push(data);
      return g;
    }, {});

    const groups = unitGroups
      .map((groupName) => {
        let order = false;
        let data = [];
        if(groupsObject[groupName]?.length) {
         data = groupsObject[groupName].filter((obj) => {
          // && obj?.Value !== "999.999"

          // PropertyID 113 is houisng benefit propery and we dont won't to check it
          if(!order && obj?.Value !== null && obj?.Value !== "" && obj?.Value !== "0" && obj?.Value !== 0 && obj?.Value !== "999.999" && obj?.PropertyID !== 113) order = true;
            return obj?.Value !== null && obj?.Value !== "" && obj?.Value !== "0" && obj?.Value !== 0 && obj?.Value !== "999.999"
          })
        }
        return {
          title: groupName,
          // data : groupsObject[groupName],
          data: data,
          order: order
            ? groupsObject[groupName][0]?.GroupOrder
            :  0,
        };
      })
      .sort((a, b) => {
        return a.order - b.order;
      });

    return groups;
  }

  getStatistics() {
    const {
    Bookmarked      = "",
    BookmarkedLabel = "",
    Seen            = "",
    SeenLabel       = "",
    LastSeen        = "",
    LastSeenLabel   = "",
    }               = this.data?.Statistics ?? [];
    
    return {
      bookmarked: {
        bookmarkedLabel: this?.data?.Statistics?.BookmarkedLabel,
        bookmarkedValue: this.data?.Statistics?.Bookmarked
      },
      seen: {
        seenLabel: this.data?.Statistics?.SeenLabel,
        seenValue: this.data?.Statistics?.Seen
      },
      lastSeen: {
        LastSeenLabel : this.data?.Statistics?.LastSeenLabel,
        LastShownValue: this.data?.Statistics?.LastSeen
      },
    };
  }

  getFloorplanUrl(thumbs = false) {
    return thumbs
      ? this.data?.Gallery?.Floorplan?.UrlThumbnail
      :  this.data?.Gallery?.Floorplan?.Url ?? "";
  }

  getUrl(base = "") {
    const id = this.getId();
    return `${base}/unit/${id}`;
  }
  
  getArea() {
    return this.getValue("Areal")+" "+this.getPostfix("Areal");
  }

  getNoOfRooms() {
    return this.getValue("VaerelserAntal");
  }
}

export default Unit;
