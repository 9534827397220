import { Box } from "@mui/material";
import { FlexBox, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation } from "react-router-dom";
import { getFocus } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";

export const OverviewDualMenu = ({left, right, nav, pin, openSelectFunction, showSelectFunction, setShowSelectFunction, clickList, list, buttonsLabel, showProperty, setShowProperty, toggleDrawer, config, rotatemode, click2, turnBuilding, isrotate, click1, clickModel}) => {
    const type = getFocus(_bootstrap, "type");

    const isMobile = useBreak("md_dn");
    const here = useLocation().pathname;
    const isIsometry = here.includes("isometry")

    const frame = getIsoData("activeframe");
    const frames = getIsoData("frames");
    const index = getIsoData("snapindex");
    const setIndex = setIsoData("snapindex");
    const setFrame = setIsoData("activeframe");
    const percent = getIsoData('percent')

    const showButton = [
        "list",
        "compare",
        "favorites",
        "gallery",
        "downloads",
      ].some((i) => here.includes(i));
    
    const props = { type, isIsometry, pin }
    return <>
        <FullWidth
          isMobile={isMobile}
          left={left}
          right={right}
          nav={nav}
          clr={clr}
          isBgTrue={isMobile ? true: false}
        >
            <FlexBox clr={clr}> 
            {
                isMobile ? 
                <Section clr={clr} onClick={openSelectFunction} padding="20px">
                    <Icon
                        icon="ellipsisvertical"
                        color={clr.primary_text}
                        isFaIcon={true}
                        size="xl"
                    />
                    <SelectFunction
                        showSelectFunction={showSelectFunction}
                        setShowSelectFunction={setShowSelectFunction}
                    />
                </Section>
                :
                <>
                    <Box
                    sx={{
                        width: isMobile && !isIsometry ? '100%' : "auto",
                        display: "flex",
                        justifyContent: isMobile && !isIsometry ? "space-between" : "flex-start",
                        gap: "16px",
                        position: "relative",
                    }}
                    >
                        <Section
                        {...props}
                            clr={clr}
                            active={here.includes("list")}
                            onClick={clickList}
                        >
                            <Icon {...list} isFaIcon={true} color={"#000"} />
                            <div className="paragraph1-regular" style={{ color: "#000" }}>
                            {
                                buttonsLabel?.filter(
                                (btn) => btn.UILabelProperty === "Boligliste"
                                )[0]?.Label
                            }
                            </div>
                        </Section>
                        <Section
                        {...props}
                        onClick={() => {
                            setShowProperty(!showProperty);
                            toggleDrawer();
                        }}
                        clr={clr}
                        showButton={showButton}
                        >
                        <Icon
                            icon="buildings"
                            size="lg"
                            color={"#000"}
                            isFaIcon={true}
                        />
                        <div className="paragraph1-regular"  style={{ color: "#000" }}>
                            Skift ejendom
                        </div>
                        <ShiftProperty
                        {...props}
                            showProperty={showProperty}
                            setShowProperty={setShowProperty}
                        />
                        </Section>
                        {(config?.IMAGE_SNAPFRAMES?.length > 0) && isIsometry && !isMobile && (
                            <Section
                            {...props}
                            clr={clr}
                            onClick={rotatemode && isrotate ? click1 : click2}
                            >
                            <>
                                {!turnBuilding ? (
                                <>
                                    <Icon
                                    icon="rightarrowrotate"
                                    color={"#000"}
                                    isFaIcon={true}
                                    size="lg"
                                    />{" "}
                                    <div className="paragraph1-regular"  style={{ color: "#000" }}>
                                    {
                                        buttonsLabel?.filter(
                                        (btn) => btn?.UILabelProperty === "Drejbygning"
                                        )[0]?.Label
                                    }
                                    </div>
                                </>
                                ) : (
                                <>
                                    <Icon
                                    icon={isrotate ? "pause" : "play"}
                                    color={"#000"}
                                    isFaIcon={true}
                                    size="lg"
                                    />{" "}
                                    <p style={{ color: "#000" }}>{isrotate ? "Stop rotation" : "Start rotation"}</p>
                                </>
                                )}
                            </>
                            </Section>
                        )}
                    </Box>
                    <Box>
                        <Section width="none">
                            {!rotatemode && percent == 100 && (
                                <>
                                {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                                    <Controls
                                    {...{
                                        frame,
                                        setFrame,
                                        config,
                                        frames,
                                        index,
                                        setIndex,
                                    }}
                                    />
                                )}
                                </>
                            )}
                        </Section>
                    </Box>
                </>
            }
             
            </FlexBox>
          {/* <FlexBox clr={clr}>
            <Box
              sx={{
                width: isMobile && !here.includes("isometry") ? '100%' : "auto",
                display: "flex",
                justifyContent: isMobile && !here.includes("isometry") ? "space-between" : "flex-start",
                gap: "8px",
                position: "relative",
              }}
            >
              {isMobile && (pin == 0 && here.includes("isometry")) && (
                <Section pin={pin} clr={clr} onClick={openSelectFunction} padding="20px">
                  <Icon
                    icon="ellipsisvertical"
                    color="#000"
                    isFaIcon={true}
                    size="xl"
                  />
                  <SelectFunction
                    showSelectFunction={showSelectFunction}
                    setShowSelectFunction={setShowSelectFunction}
                  />
                </Section>
              )}

              {here.includes("isometry") ? (
                <Section
                  pin={pin}
                  clr={clr}
                  active={here.includes("list")}
                  onClick={clickList}
                  isMobile={isMobile}
                >
                  <Icon {...list} isFaIcon={true} color={clr.primary_text} />
                  <div className="paragraph1-regular" style={{ color: clr?.primary_text }}>
                    {
                      buttonsLabel?.filter(
                        (btn) => btn.UILabelProperty === "Boligliste"
                      )[0]?.Label || "Liste visning"
                    }
                  </div>
                </Section>
              ) : (
                <Section
                  pin={pin}
                  clr={clr}
                  showButton={showButton}
                  active={true}
                  onClick={clickModel}
                >
                  <Icon {...model} isFaIcon={type === "mono" ? false : pin > 0 ? false : true} />
                  <div className="paragraph1-regular" style={{ color: clr?.primary_text }}>{type === "mono" ? buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "isometri"
                  )[0]?.Label : pin > 0 ? buttonsLabel?.filter(
                    (btn) => btn.UILabelProperty === "isometri"
                  )[0]?.Label : "Oversigtskort"}</div>
                </Section>
              )}
              {filterShouldShow && isDesktop && (
                <Section
                  pin={pin}
                  clr={clr}
                  showButton={showButton}
                  onClick={clickFilter}
                >
                  <Icon color={clr.primary_text} {...filter} isFaIcon={true} />
                  <div className="paragraph1-regular"  style={{ color: clr?.primary_text }}>
                    {
                      buttonsLabel?.filter(
                        (btn) => btn.UILabelProperty === "Anvendfilter"
                      )[0]?.Label || "Anvend filter"
                    }
                  </div>
                </Section>
              )}

              {!isMobile && (
                <Section
                  pin={pin}
                  onClick={() => {
                    setShowProperty(!showProperty);
                    toggleDrawer();
                  }}
                  clr={clr}
                  showButton={showButton}
                >
                  <Icon
                    icon="buildings"
                    size="lg"
                    color={pin > 0 || showButton ? "#fff" : clr?.primary_text}
                    isFaIcon={true}
                  />
                  Skift ejendom
                  <ShiftProperty
                    showProperty={showProperty}
                    setShowProperty={setShowProperty}
                  />
                </Section>
              )}
              {pin == 0 && atModel && isDesktop && (
                <Section
                  pin={pin}
                  clr={clr}
                  onClick={() => isMobile && setState(!state)}
                  onMouseEnter={() => !isMobile && setState(true)}
                  onMouseLeave={() => !isMobile && setState(false)}
                >
                  <Status
                    pin={pin}
                    state={state}
                    setState={setState}
                    isMobile={isMobile}
                    {...{ percent }}
                  />
                </Section>
              )}

              {(config?.IMAGE_SNAPFRAMES?.length > 0) && atModel && isDesktop && (
                <Section
                  pin={pin}
                  clr={clr}
                  onClick={rotatemode && isrotate ? click1 : click2}
                >
                  <>
                    {!turnBuilding ? (
                      <>
                        <Icon
                          icon="rightarrowrotate"
                          color={clr.primary_text}
                          isFaIcon={true}
                          size="lg"
                        />{" "}
                        <div className="paragraph1-regular"  style={{ color: clr?.primary_text }}>
                          {
                            buttonsLabel?.filter(
                              (btn) => btn?.UILabelProperty === "Drejbygning"
                            )[0]?.Label
                            || "Drej bygning"
                          }
                        </div>
                      </>
                    ) : (
                      <>
                        <Icon
                          icon={isrotate ? "pause" : "play"}
                          color={clr?.primary_text}
                          isFaIcon={true}
                          size="lg"
                        />{" "}
                        <p style={{ color: clr?.primary_text }}>{isrotate ? "Stop rotation" : "Pause rotation"}</p>
                      </>
                    )}
                  </>
                </Section>
              )}
            </Box>
            <Box>
              {here.includes("isometry") && (
                <Section width="none">
                  {!rotatemode && (
                    <>
                      {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                        <Controls
                          {...{
                            frame,
                            setFrame,
                            config,
                            frames,
                            index,
                            setIndex,
                          }}
                        />
                      )}
                    </>
                  )}
                </Section>
              )}
            </Box>
          </FlexBox> */}
        </FullWidth>
    </>
}