import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DownloadItem from "./DownloadItem";
import Filter from "./Filter";
import useDownloads from "./useDownloads";
import { _project } from "state/store.projects";
import { getProjectData } from "state/jotai.projectDataHooks";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

function Downloads() {
  const data = getProjectData("downloads", 0, []);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  // eslint-disable-next-line no-unused-vars
  const [type, setType] = useState("all");
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState(data);

  const handleFilter = (selected) => {
    setType(selected);

    // TODO: handle selected filter.
    setFilteredData(data);
  };
  
  return (
    <Box
      sx={{
        lineHeight: 1.4,
      }}
    >
      <Box
        sx={{
          // px: { lg: "2rem", xs: "1.25rem" },
          mb: { lg: "24px", xs: "1.875rem" },
          maxWidth: "528px",
          padding:{md: "0 48px", xs: "0 16px"},
        }}
      >
        <div className="h3"
          style={{
            marginBottom: "16px",
          }}
        >
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "DownloadMateriale"
            )[0]?.Label
          }
        </div>
        <p className="paragraph1-regular">
          {
            buttonsLabel?.filter(
              (btn) => btn.UILabelProperty === "DownloadUndertekst"
            )[0]?.Label
          }
        </p>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: {md:"calc(100vh - 252px)", xs:"calc(100vh - 240px)"},
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {data?.map((item, i) => (
            <DownloadItem index={i} key={i} {...item} />
          ))}
        </Box>
        <PoweredBy />
      </Box>
    </Box>
  );
}

export default Downloads;
