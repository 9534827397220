import React, { useEffect } from "react";
import { useMount } from "hooks/useMount";
import { Layout } from "layout/Layout";
import { Views } from "pages/Views";
import { Loading2 } from "components/Loading2/Loading2";
import { Nav } from "components/Nav/Nav";
import { Drawer } from "components/Drawer/Drawer";
import { Portals } from "portals/Portals";
import { useGet, useSet } from "state/jotai";
import { params_query, renderLayout } from "state/store.global";
import AppThemeProvider from "theme/ThemeProvider";
import "./styles/fonts/fonts.css";
import ContactDrawer from "components/Contact/ContactDrawer";
import { useMediaQuery } from "@mui/material";
import "./styles/fonts/common.css"; 
import { SnackbarProvider } from "notistack";
import { useBreak } from "hooks/useBreak";
import { useLocation } from "react-router-dom";
// import { useLog } from 'logging/logging'

export const App = () => {
  let location = useLocation();
  const params = useGet(params_query);
  const setParams = useSet(params_query);
  const matches = useMediaQuery('(max-width:1600px)');
  const isDesktop = useBreak('md_up')
  // const loga = useLog()

  useEffect(()=> {
    if(!params && location.search.length) {
      setParams(location.search)
    }
  }, [location])

  useMount(() => {
    log({ action: "app_opened" });
  });

  const shouldRender = useGet(renderLayout);

  if (shouldRender)
    return (
      <>
        <AppThemeProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }} >
          <Layout>
            {{
              Navigation: <Nav />,
              Views: <Views />,
              DrawersLeft: [<Drawer key="1" mw={ matches ? "345" : "380"}  filter style={{borderRadius: '10px',  width: !isDesktop ? '100%' : "380px"}} />],
              DrawersRight: [<Drawer key="2" w="450" detail />],
            }}
          </Layout>
          <Portals />
          <ContactDrawer />
          </SnackbarProvider>
        </AppThemeProvider>
      </>
    );

  return <Loading2 />;
};
// App.whyDidYouRender = true
