import { Box, styled, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Address from "./Address";
import Buttons from "./Buttons";
import CTA from "./CTA";
import Description from "./Description";
import Downloads from "./Downloads";
import { FloorplansModalButton } from "./FloorplansModal";
import Footer from "./Footer";
import Gallery from "./Gallery";
import Highlights from "./Highlights";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import Price from "./Price";
import Properties from "./Properties";
import RelatedUnits from "./RelatedUnits";
import SignUpForm from "./SignUpForm";
import Statistics from "./Statistics";
import Unit from "./Unit.model";
import { get, label } from "components/Favorites/Favorites.Utilities";
import { useGet, getFocus } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import ReservationButton from "./ReservationButton";
import { BackButton } from "components/Button/BackButton";

const UnitPageMobile = ({ unit }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const unitId = unit.getId();
  const address = unit.getAddress();
  const townAndZipcode = unit.getTownZipCode();

  const status = unit.getStatus();
  const images = unit.getImages();
  const highlights = useUnitHighlight(unit);
  const isRent = unit.isRent();
  const price = unit.getPrice();
  const properties = unit.getProperties();

  const descTitle = unit.getDescriptionTitle();
  const description = unit.getDescription();
  const areaDescTitle = unit.getAreaDescriptionTitle();
  const areaDescription = unit.getAreaDescription();
  const filteredProperties = properties.filter(property => property?.order !== 0)

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: "5px"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", padding: "3px 0", justifyContent: "space-between"  }}>
          <BackButton style={{ position: "unset", gap: "8px" }} color={"#000"} />
          <FloorplansModalButton unit={unit} />
        </Box>
        <Box
          sx={{
            backgroundColor: `${status.color}e6`,
            color: "#ffffff",
            position: "absolute",
            zIndex: 2,
            right: '8px',
            top: '66px',
            borderRadius: '6px',
            padding: '8px 24px',
          }}
        >
          {status.label}
        </Box>
        <Box sx={{ position: "relative" }}>
          <Gallery images={images.slice(1)} hideThumbs={true} />

        </Box>
        <Buttons unitId={unitId} address={ (address || townAndZipcode ) ? `${address}, ${townAndZipcode}` : ""} rent={price} highlights={highlights}/>
        <OpenHouse />

        <Box sx={{ mt: 3 }}>
          <Address>
            <p>{unit.getAddress()},</p>
            <span>{unit.getTownZipCode()}</span>
          </Address>

          <Box sx={{ mt: 3 }}>
            <Highlights items={highlights} />
          </Box>

          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Price
              label={
                isRent ? `${get(unit.data, "LejelejlighedsText")}` : `${label(unit.data, "Kontantpris")}`
              }
              value={price}
            />
          </Box>

          <Box sx={{ mt: 3, px: 2.8 }}>
            {/* <CTA /> */}
            <ReservationButton />
          </Box>
        </Box>
        <Box
          sx={{
            // backgroundColor: "#F3F3F3",
            mt: "40px",
            mb: "20px",
            padding: "48px 20px",
            fontSize: 16,
          }}
        >
          <Properties groups={filteredProperties} indent />

          <Downloads />
        </Box>

        <Box sx={{ mt: "40px", mb: "20px", background: `linear-gradient(180deg, ${clr.primary} 0%, ${clr.primary}66 100%)` }}>
          <StyledTabs
            value={activeTab}
            onChange={handleChange}
            variant="fullWidth"
          >
            <StyledTab
            sx={{borderRadius : "6px 0 0 6px"}}
              label={`${buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Boligerne"
              )[0]?.Label
                }`}
            />
            <StyledTab
            sx={{borderRadius : "0 6px 6px 0"}}
              label={`${buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Området"
              )[0]?.Label
                }`}
            />
            {/* <StyledTab
            label={`${
              buttonsLabel?.filter((btn) => btn.UILabelProperty === "Kort")[0]
                ?.Label
            }`}
          /> */}
          </StyledTabs>

          <TabPanel active={0 === activeTab} index={0}>
            <Description  overlayColor={clr.primary} header={descTitle} content={description} color="#fff"/>
          </TabPanel>
          <TabPanel active={1 === activeTab} index={1}>
            <Description  overlayColor={clr.primary} header={areaDescTitle} content={areaDescription} color="#fff"/>
          </TabPanel>
          <TabPanel active={2 === activeTab} index={2}>
            <Box sx={{ height: 400, backgroundColor: "grey" }}></Box>
          </TabPanel>
        </Box>
        
        <Box mb={4}>
          <RelatedUnits unit={unit} />
        </Box>

        <Box sx={{ mb: { xs: 0, lg: 4 }, mt: { xs: 0, lg: 4 } }}>
          <Statistics unit={unit} />
        </Box>

        <Box sx={{ background: `linear-gradient(180deg, ${clr.primary} 0%, ${clr.primary}66 100%)`, color: "secondary.contrastText" }}>
        <SignUpForm unit={unit} />
          <Box>
            <Footer />
            <PoweredBy clr={clr?.primary_text}/>
          </Box>
        </Box>
      </Box>
    </>
  );
};

UnitPageMobile.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

function TabPanel(props) {
  const { children, active } = props;

  return (
    <>
      {active && (
        <Box sx={{ position: 'relative' }}>
          <Box>{children}</Box>
        </Box>
      )}
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

const StyledTabs = styled((props) => <Tabs {...props} />)({
  padding: "16px 24px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 700,
  fontSize: 16,
  marginRight: 0,
  color: "#fff",
  backgroundColor: "clr?.primary",
  border:"1px solid #fff",
  // borderRadius:"6px",
  // borderRight: "none",
  transition: "all 0.3s",
  // "&:last-child": {
  //   borderRight: `1px solid ${theme.palette.primary.main}bf`,
  // },
  "&.Mui-selected": {
    border: `1px solid ${theme.palette.primary.main}bf`,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export default UnitPageMobile;
